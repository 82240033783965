<template>
    <v-row class="mt-2">
        <v-col class="d-flex justify-center" cols="12">
            <v-card
                class="d-flex profile-card"
                color="primary lighten-5"
                width="400"
                height="150"
                flat
            >
                <v-card-title>
                    <avatar size="80" :file="tempFile"></avatar>
                    <v-row justify="center">
                        <v-btn
                            v-if="editingAvatar"
                            fab
                            x-small
                            color="garden darken-1"
                            class="position"
                            @click="updateAvatarImage()"
                        >
                            <v-icon color="white">
                                fa-solid fa-check
                            </v-icon>
                        </v-btn>

                        <v-file-input
                            v-if="!editingAvatar"
                            v-model="tempFile"
                            :rules="rules"
                            accept="image/png, image/jpeg, image/bmp"
                            label="Avatar"
                            hide-input
                            prepend-icon="fas fa-camera"
                        >
                        </v-file-input>
                        <v-btn
                            v-if="editingAvatar"
                            fab
                            x-small
                            color="error darken-1"
                            class="position-up"
                            @click="removeImage()"
                        >
                            <v-icon color="white">
                                fa-solid fa-xmark
                            </v-icon>
                        </v-btn>
                    </v-row>
                </v-card-title>
                <v-card-text class="align-self-center">
                    <div>
                        <h1>{{ profile.name }}</h1>
                        <span>{{ profile.email }}</span>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" class="d-flex flex-column align-center">
            <!-- update name dialog -->
            <update-dialog
                v-if="!isWithSocial"
                :label="$t(`profile.dialog.editName`)"
                :open.sync="closeDialog"
                :close="closeDialog"
            >
                <template #content>
                    <text-input
                        :label="$t(`profile.name`)"
                        :current-value="profile.name"
                        :input-value.sync="profile.name"
                    ></text-input>
                </template>
                <template #action>
                    <v-btn
                        color="primary"
                        text
                        @click="updateProfile('name', profile.name)"
                    >
                        {{ $t(`profile.dialog.updateBtn`) }}
                    </v-btn>
                </template>
            </update-dialog>
            <!-- update email dialog  -->
            <update-dialog
                v-if="!isWithSocial"
                :label="$t(`profile.dialog.editEmail`)"
                :open.sync="closeDialog"
                :close="closeDialog"
            >
                <template #content>
                    <email-input
                        :current-email="profile.email"
                        :label="$t(`profile.email`)"
                        :email.sync="profile.email"
                        :autofocus="false"
                    ></email-input>
                </template>
                <template #action>
                    <v-btn
                        color="primary"
                        text
                        @click="updateProfile('email', profile.email)"
                    >
                        {{ $t(`profile.dialog.updateBtn`) }}
                    </v-btn>
                </template>
            </update-dialog>
            <!-- update password dialog  -->
            <update-dialog
                v-if="!isWithSocial"
                :label="$t(`profile.dialog.editPassword`)"
                :open.sync="closeDialog"
                :close="closeDialog"
            >
                <template #content>
                    <password-input
                        :password.sync="cuurentPassword"
                        :value="cuurentPassword"
                        :label="$t(`auth.currentPassword`)"
                    ></password-input>
                    <password-input
                        :password.sync="newPassword"
                        :value="newPassword"
                        :label="$t(`auth.newPassword`)"
                    ></password-input>
                    <confirm-password-input
                        :password="newPassword"
                        :value="confirmNewPassword"
                        :confirm-password.sync="confirmNewPassword"
                        :label="$t(`auth.confirmNewPassword`)"
                    ></confirm-password-input>
                </template>
                <template #action>
                    <v-btn color="primary" text @click="updatePassword()">
                        {{ $t(`profile.dialog.updateBtn`) }}
                    </v-btn>
                </template>
            </update-dialog>
            <!-- update character dialog  -->
            <update-dialog
                :label="$t(`profile.dialog.editCharacter`)"
                :open.sync="closeDialog"
                :close="closeDialog"
            >
                <template #content>
                    <v-row>
                        <v-col cols="12" class="pa-0">
                            <select-hero
                                max-width="100%"
                                :selected-hero.sync="selectedHero"
                            ></select-hero>
                        </v-col>
                    </v-row>
                </template>
                <template #action>
                    <v-btn color="primary" text @click="selectHero()">
                        {{ $t(`profile.dialog.finish`) }}
                    </v-btn>
                </template>
            </update-dialog>
            <!-- Delete user dialog  -->
            <update-dialog
                :label="$t(`profile.dialog.deleteUser`)"
                :open.sync="closeDialog"
                :color="'error'"
                :close="closeDialog"
            >
                <template #content>
                    <v-row>
                        <v-col cols="12" class="pa-0">
                            {{ $t(`profile.dialog.deleteUserText`) }}
                        </v-col>
                    </v-row>
                </template>
                <template #action>
                    <v-btn color="error" text @click="confirmDelete()">
                        {{ $t(`profile.dialog.delete`) }}
                    </v-btn>
                </template>
            </update-dialog>
        </v-col>
        <v-col cols="12" class="mb-4 pa-0 d-flex justify-center text-center">
            <v-card class="mx-auto" max-width="480" color="appBackground" flat>
                <v-card-title>
                    <h3 class="word__wrap">
                        {{
                            $t(`landing.selectedDescription`, {
                                character: $t(`landing.characters.${hero}`),
                            })
                        }}
                    </h3>
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                    <hero animation-is="jump" :size="200"></hero>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import {
    getProfile,
    updateProfile,
    updatePassword,
    deleteProfile,
} from "@/api/profileApi.js";
import { setHero } from "@/api/chatbotApi.js";
import { mapState } from "vuex";
import { apiStatus } from "@/api/constants/apiStatus";

import Avatar from "@/components/General/Avatar.vue";
import Hero from "@/components/Heroes/Hero.vue";
export default {
    name: "Profile",

    components: {
        Avatar,
        UpdateDialog: () => import("./Profile/components/updateDialog.vue"),
        TextInput: () => import("@/components/General/TextInput.vue"),
        EmailInput: () => import("@/components/General/EmailInput.vue"),
        PasswordInput: () => import("@/components/General/PasswordInput.vue"),
        ConfirmPasswordInput: () =>
            import("@/components/General/ConfirmPasswordInput.vue"),
        SelectHero: () => import("@/components/General/SelectHero.vue"),
        Hero,
    },

    data() {
        return {
            profile: {},
            selectedHero: "",
            cuurentPassword: null,
            newPassword: null,
            confirmNewPassword: null,
            closeDialog: false,

            editingAvatar: false,
            tempFile: null,
            rules: [
                (value) =>
                    !value ||
                    value.size < 2000000 ||
                    "Avatar size should be less than 2 MB!",
            ],
        };
    },

    computed: {
        ...mapState({
            hero: (state) => state.auth.hero,
        }),
        ...mapState({
            user_role: (state) => state.auth.user.role,
            isWithSocial: (state) => state.auth.user.social_login,
        }),
    },

    methods: {
        async handleProfile() {
            let url = `${this.user_role}`;
            const { response, error } = await withAsync(getProfile, url);

            if (error) {
                return;
            }

            this.selectedHero = response.data.data.chatbot_character;
            this.profile = response.data.data;
        },
        takePicture() {},
        removeImage() {
            this.tempFile = null;
        },
        async updateAvatarImage() {
            const formData = new FormData();
            let url = `${this.user_role}/update`;
            formData.append("image_url", this.tempFile);
            const payload = formData;
            const { response, error } = await withAsync(
                updateProfile,
                payload,
                url
            );

            if (error) {
                return;
            }

            this.editingAvatar = false;
            this.$store.dispatch("auth/setUser", response.data.data);
        },

        async updateProfile(key, value) {
            let url = `${this.user_role}/update`;

            const payload = {
                [key]: value,
            };

            const { response, error } = await withAsync(
                updateProfile,
                payload,
                url
            );

            if (error) {
                return;
            }
            this.$store.dispatch("auth/setUser", response.data.data);

            this.closeDialog = true;
        },
        async updatePassword() {
            let url = `${this.user_role}/change-password`;
            const payload = {
                current_password: this.cuurentPassword,
                password: this.newPassword,
                password_confirm: this.confirmNewPassword,
            };

            const { response, error } = await withAsync(
                updatePassword,
                payload,
                url
            );

            if (error) {
                return;
            }
            this.closeDialog = true;
            this.newPassword = "";
            this.confirmNewPassword = "";
            this.cuurentPassword = "";
        },

        async selectHero() {
            let url = `${this.user_role}/chatbot/update`;

            const payload = {
                chatbot_char: this.selectedHero,
            };
            const { response, error } = await withAsync(setHero, payload, url);

            if (error) {
                return;
            }

            this.$store.dispatch("auth/setHero", response.data.data.type);
            this.closeDialog = true;
        },
        deleteProfile() {
            this.closeDialog = true;
        },

        async confirmDelete() {
            this.deleteStatus = apiStatus.Pending;
            const { response, error } = await withAsync(deleteProfile);

            if (error) {
                this.deleteStatus = apiStatus.Error;
                return;
            }

            this.deleteStatus = apiStatus.Success;
            this.$store.dispatch("auth/setLogout");
            this.$router.replace({ name: "Home" });
        },
    },

    watch: {
        tempFile(newVal) {
            if (newVal) this.editingAvatar = true;
            else this.editingAvatar = false;
        },
    },

    created() {
        this.handleProfile();
    },
};
</script>
