import { api } from "./api";

const URLS = {
    user: "user",
    update: "user/update/",
};

export const getProfile = (url) => {
    return api.get(url);
};

export const updateProfile = (paylaod, url) => {
    const params = {
        _method: "PATCH",
    };
    return api.post(url, paylaod, { params });
};

export const updatePassword = (paylaod, url) => {
    return api.put(url, paylaod);
};

export const deleteProfile = () => {
    return api.delete(URLS.user);
};
